<template>
  <div class="container">
    <div :class="{ summary: true, daterange: date.rangeType === 'daterange' }">
      <PrevSummary
        :title="prevDataTitle"
        :summaryItem="prevData"
        v-if="date.rangeType !== 'daterange'"
        type="lecture"
        v-loading="prevLoading"
      />
      <SummaryNew
        :summaryItems="dashBoardData"
        type="lecture"
        @handleSelectChange="handleSelectChange"
        :name="filterOptions[0].name"
        :value="filterOptions[0].value"
        v-loading="loading"
      />
    </div>
    <FilterNew
      :filterOptions="filterOptions"
      :refreshClick="refresh"
      downloadText="엑셀 다운로드"
      :handleDownload="downloadExcel"
    />
    <SubHeaderNew
      :count="total"
      :amount="totalAmount"
      :userCount="userCount"
      :search="search"
      :handleSearchChange="handleSearchChange"
      :searchResult="searchResult"
      placeholder="회원명 검색"
    />
    <ListTableNew :tableData="filteredPagedData" type="lecture" v-loading="loading" :sort="sort" :sortChange="handleSortChange" />
    <el-pagination
      :current-page.sync="page"
      :page-size="limit"
      :pager-count="5"
      layout="prev, pager, next, sizes"
      :total="userCount"
      v-show="filteredPagedData.length > 0"
      :page-sizes="[10, 15, 20, 50]"
      @size-change="size => (limit = size)"
    />
  </div>
</template>

<script>
import SummaryNew from '@components/Sales/SummaryNew';
import PrevSummary from '@components/Sales/PrevSummary';
import FilterNew from '@components/Sales/FilterNew';
import SubHeaderNew from '@components/Sales/SubHeaderNew';
import ListTableNew from '@components/Sales/ListTableNew';
import { PAYMENT_VAR } from '@constants';
import moment from 'moment';
import { BOOKING_STATUS_LABEL } from '@constants';

export default {
  components: {
    SummaryNew,
    PrevSummary,
    FilterNew,
    SubHeaderNew,
    ListTableNew,
  },
  data() {
    return {
      prevData: {
        totalCount: 0,
        totalAmount: 0,
        groupCount: 0,
        groupAmount: 0,
        privateCount: 0,
        privateAmount: 0,
      },

      dashBoardData: [
        { name: 'total', count: 0, amount: 0, label: '합계' },
        { name: 'G', count: 0, amount: 0, label: '그룹' },
        { name: 'P', count: 0, amount: 0, label: '프라이빗' },
      ],

      filterOptions: [
        {
          name: 'lectureType',
          onChange: this.handleSelectChange,
          options: [
            { value: 'total', label: '수업 전체' },
            { value: 'G', label: '그룹' },
            { value: 'P', label: '프라이빗' },
          ],
          value: 'total',
        },
        {
          name: 'ticketType',
          onChange: this.handleSelectChange,
          options: [
            { value: 'total', label: '수강권 유형 전체' },
            { value: 'T', label: '횟수제' },
            { value: 'D', label: '차감제' },
            { value: 'P', label: '기간제' },
          ],
          value: 'total',
        },
        {
          name: 'ticketId',
          onChange: this.handleSelectChange,
          options: [],
          value: [],
          multiple: true,
          placeholder: '수강권 전체',
        },
        {
          name: 'staffId',
          onChange: this.handleSelectChange,
          options: [],
          value: [],
          multiple: true,
          placeholder: '강사 전체',
        },
      ],
      page: 1,
      limit: 10,
      search: '',
      loading: false,
      prevLoading: false,
      wholeData: [],
      filteredData: [],
      sort: 'ascending',
    };
  },

  mounted() {
    this.fetchStaffs();
    this.fetchTickets();
    this.fetchData();
    this.fetchPrevData();
    moment.locale('ko');
  },

  computed: {
    date() {
      return this.$store.getters['salesNew/filter'];
    },
    prevDataTitle() {
      if (this.date?.rangeType === 'day') return '어제';
      else if (this.date?.rangeType === 'week') return '지난 주';
      else if (this.date?.rangeType === 'month') return '지난 달';
      else return '';
    },

    filteredPagedData() {
      const startIndex = this.limit * (this.page - 1);
      const endIndex = startIndex + this.limit;
      const pagedData = this.filteredData.slice(startIndex, endIndex);
      return pagedData;
    },

    total() {
      const filterData = this.filteredData.filter(data => data.lectureId);
      const filteredDataSet = new Set(filterData.map(data => data.lectureId));
      return filteredDataSet.size;
    },

    totalAmount() {
      return this.filteredData.reduce((prev, cur) => prev + cur.deductedAmount, 0);
    },

    userCount() {
      return this.filteredData.length;
    },
    refreshAll() {
      return this.$store.getters['salesNew/refreshAll'];
    },
  },

  watch: {
    date: function() {
      this.page = 1;
      this.fetchData();
      this.fetchPrevData();
      this.fetchTickets();
      this.fetchStaffs();
    },
    filterOptions: function() {
      this.getFilteredData();
    },
    wholeData: function() {
      this.getDashBoardData();
      this.getFilteredData();
      this.fetchTickets();
      this.fetchStaffs();
    },
    refreshAll: function(value) {
      if (value) {
        this.fetchPrevData();
        this.fetchData();
        this.fetchTickets();
        this.fetchStaffs();
      }
    },
    sort: function() {
      this.getFilteredData();
    },
  },

  methods: {
    async getLectures(startDate, endDate) {
      const start = moment(startDate);
      const end = moment(endDate);
      const diff = end.diff(moment(start), 'days');

      const weeks = [];

      if (diff < 6) {
        weeks.push({ start: startDate, end: endDate });
      } else {
        /** 주 단위로 나눠서 요청할 수 있도록 배열 가공 */
        let current = start.clone();
        while (current.isSameOrBefore(end)) {
          const weekStart = current.clone();
          const weekEnd = current.clone().endOf('week');

          if (weekEnd.isAfter(end)) {
            weekEnd.set({ year: end.year(), month: end.month(), date: end.date() });
          }

          current = current.add(1, 'week').startOf('week');
          weeks.push({
            start: weekStart.format('YYYY-MM-DD'),
            end: weekEnd.format('YYYY-MM-DD'),
          });
        }
      }

      const salesLectures = [];

      /** 주 단위 요청 로직 */
      for (const week of weeks) {
        const params = {
          start_date: week.start,
          end_date: week.end,
          limit: 100,
        };

        const results = await this.$utils.promiseAllData(this.$api.salesNew.getLectures, params);
        salesLectures.push(...results);
      }

      return salesLectures;
    },

    async getDeducts(startDate, endDate) {
      const params = {
        start_date: startDate,
        end_date: endDate,
      };
      const deducts = await this.$utils.promiseAllData(this.$api.salesNew.getDeducted, params);

      return deducts;
    },

    async getWholeData(startDate, endDate) {
      const lectures = await this.getLectures(startDate, endDate);
      const deducts = await this.getDeducts(startDate, endDate);
      const wholeData = [...lectures, ...deducts]
        .map(item => {
          const lectureId = item.lecture?.id;
          const lectureType = item.ticket.class_type;
          const ticketType = item.ticket.use_weekly_auto_coupon_balance === 1 || !item.status ? 'D' : item.ticket.type;
          const staffId = item.lecture?.staff_id;
          const ticketId = item.ticket.id;
          const isPeriod = item.ticket.type === 'P';
          const lectureTime = item.status ? { startOn: item.lecture.start_on, endOn: item.lecture.end_on } : null;
          const memberName = item.member_name;
          const ticketName = item.ticket.name;
          const staffName = item.status ? item.lecture.staff_name : '-';
          const status = item.status;
          const settlementAt = item.status ? item.lecture.start_on : item.created_at;
          const memberMobile = item.member_mobile;
          const maxCoupon = item.ticket.max_coupon;

          const { total: totalAmount, perCoupon: oneTimeAmount } = this.getTotalAmount(maxCoupon, item.payments);
          const deductedCount = item.coupon_deduction;
          const deductedAmount = deductedCount * oneTimeAmount;
          const cumulativeCount = item.cumulative.booked + item.cumulative.deducted;
          const cumulativeAmount = cumulativeCount * oneTimeAmount;
          const remainingCount = maxCoupon > cumulativeCount ? maxCoupon - cumulativeCount : 0;
          const remainingAmount = remainingCount * oneTimeAmount;
          const memberId = item.member_id;

          return {
            lectureId,
            lectureType,
            ticketType,
            lectureTime,
            memberName,
            staffName,
            ticketName,
            isPeriod,
            status,
            maxCoupon,
            totalAmount,
            oneTimeAmount: ticketType === 'P' ? 0 : Math.ceil(oneTimeAmount),
            deductedCount: ticketType === 'P' ? 0 : deductedCount,
            deductedAmount: ticketType === 'P' ? 0 : Math.ceil(deductedAmount),
            cumulativeCount: ticketType === 'P' ? 0 : cumulativeCount,
            cumulativeAmount: ticketType === 'P' ? 0 : Math.ceil(cumulativeAmount),
            remainingCount: ticketType === 'P' ? 0 : remainingCount,
            remainingAmount: ticketType === 'P' ? 0 : Math.ceil(remainingAmount),
            settlementAt,
            staffId,
            ticketId,
            memberMobile,
            memberId,
          };
        })
        .sort((d1, d2) => (moment(d1.settlementAt).isAfter(moment(d2.settlementAt)) ? -1 : 1));
      return wholeData;
    },

    async fetchData() {
      try {
        this.loading = true;
        const wholeData = await this.getWholeData(this.date.date, this.date.endDate);
        this.wholeData = wholeData;
      } catch (e) {
        this.$utils.notify.parseError(this, e);
      } finally {
        this.loading = false;
        if (!this.prevLoading) this.$store.dispatch('salesNew/setRefreshAll', false);
      }
    },

    async fetchPrevData() {
      if (this.date.rangeType === 'daterange') return;
      try {
        this.prevLoading = true;
        const startDate = moment(this.date.date)
          .subtract(1, this.date.rangeType)
          .format('YYYY-MM-DD');
        const endDate =
          this.date.rangeType === 'month'
            ? moment(this.date.date)
                .subtract(1, 'day')
                .format('YYYY-MM-DD')
            : moment(this.date.endDate)
                .subtract(1, this.date.rangeType)
                .format('YYYY-MM-DD');

        const wholePrevData = await this.getWholeData(startDate, endDate);
        let privateCount = 0;
        let privateAmount = 0;
        let groupCount = 0;
        let groupAmount = 0;
        wholePrevData.forEach(data => {
          if (data.lectureType === 'P') {
            privateCount++;
            privateAmount += data.deductedAmount;
          } else {
            groupCount++;
            groupAmount += data.deductedAmount;
          }
        });

        const newPrevData = {
          privateCount,
          privateAmount,
          groupCount,
          groupAmount,
          totalCount: privateCount + groupCount,
          totalAmount: privateAmount + groupAmount,
        };
        this.prevData = newPrevData;
      } catch (e) {
        this.$utils.notify.parseError(this, e);
      } finally {
        this.prevLoading = false;
        if (!this.loading) this.$store.dispatch('salesNew/setRefreshAll', false);
      }
    },

    getTotalAmount(maxCoupon, payments) {
      const exceptTotalPaymentStatus = ['installment_payment', 'full_payment', 'refund'];
      const { total } = payments.reduce(
        ({ beforeAmount, total }, payment) => {
          if (payment.status === PAYMENT_VAR.transfer) {
            total = beforeAmount + payment.before_user_ticket_amount;
          } else if (exceptTotalPaymentStatus.indexOf(payment.status) === -1 || !payment.status) {
            // !payment.status는 회원 생성시에 수강권 등록할 때
            total = beforeAmount + payment.amount + payment.unpaid_amount;
          }
          beforeAmount = total;

          return { beforeAmount, total };
        },
        { beforeAmount: 0, unpaid: 0, total: 0 },
      );

      const perAmount = maxCoupon <= 0 ? 0 : total / maxCoupon;
      return { total, perCoupon: perAmount };
    },

    getFilteredData() {
      let filteredData = this.sort === 'ascending' ? [...this.wholeData].reverse() : [...this.wholeData];
      const lectureType = this.filterOptions.find(option => option.name === 'lectureType').value;
      const ticketType = this.filterOptions.find(option => option.name === 'ticketType').value;
      const ticketId = this.filterOptions.find(option => option.name === 'ticketId').value;
      const staffId = this.filterOptions.find(option => option.name === 'staffId').value;

      if (lectureType !== 'total') {
        filteredData = filteredData.filter(data => data.lectureType === lectureType);
      }
      if (ticketType !== 'total') {
        filteredData = filteredData.filter(data => data.ticketType === ticketType);
      }
      if (ticketId.length > 0) {
        filteredData = filteredData.filter(data => ticketId.includes(data.ticketId));
      }
      if (staffId.length > 0) {
        filteredData = filteredData.filter(data => staffId.includes(data.staffId));
      }
      if (this.search !== '') {
        filteredData = filteredData.filter(data => data.memberName.includes(this.search));
      }

      this.filteredData = filteredData;
    },

    getDashBoardData() {
      let privateCount = 0;
      let privateAmount = 0;
      let groupCount = 0;
      let groupAmount = 0;
      this.wholeData.forEach(data => {
        if (data.lectureType === 'P') {
          privateCount++;
          privateAmount += data.deductedAmount;
        } else {
          groupCount++;
          groupAmount += data.deductedAmount;
        }
      });
      const newDashBoardData = [...this.dashBoardData];
      newDashBoardData.find(data => data.name === 'total').count = privateCount + groupCount;
      newDashBoardData.find(data => data.name === 'total').amount = privateAmount + groupAmount;
      newDashBoardData.find(data => data.name === 'P').count = privateCount;
      newDashBoardData.find(data => data.name === 'P').amount = privateAmount;
      newDashBoardData.find(data => data.name === 'G').count = groupCount;
      newDashBoardData.find(data => data.name === 'G').amount = groupAmount;
      this.dashBoardData = newDashBoardData;
    },

    async fetchTickets() {
      try {
        const uniqFilterData = _.uniqBy(this.wholeData, 'ticketId');
        const ticketOptions = uniqFilterData.map(lecture => {
          return {
            value: lecture.ticketId,
            label: lecture.ticketName,
          };
        });
        this.filterOptions.find(option => option.name === 'ticketId').options = ticketOptions;
      } catch (e) {
        this.$utils.notify.parseError(this, e);
      }
    },

    async fetchStaffs() {
      try {
        const uniqFilterData = _.uniqBy(this.wholeData, 'staffId');
        const staffOptions = uniqFilterData.map(lecture => {
          return {
            value: lecture.staffId,
            label: lecture.staffName,
          };
        });
        this.filterOptions.find(option => option.name === 'staffId').options = staffOptions;
      } catch (e) {
        this.$utils.notify.parseError(this, e);
      }
    },

    handleSelectChange(name, value) {
      const newFilterOptions = [...this.filterOptions];
      newFilterOptions.find(filter => filter.name === name).value = value;
      this.filterOptions = newFilterOptions;
      this.page = 1;
    },
    refresh() {
      this.handleSelectChange('lectureType', 'total');
      this.handleSelectChange('ticketType', 'total');
      this.handleSelectChange('ticketId', []);
      this.handleSelectChange('staffId', []);
    },
    handleSearchChange(value) {
      this.search = value;
    },

    searchResult() {
      this.page = 1;
      this.getFilteredData();
    },

    formatJSON(data) {
      if (!data || !data.length) return [];

      return data.map(item => {
        const ticketTypeMap = {
          T: '횟수제',
          P: '기간제',
          D: '차감제',
        };
        const lectureType = item.lectureType === 'P' ? '프라이빗' : '그룹';
        const ticketType = ticketTypeMap[item.ticketType];
        const lectureDate = moment(item.lectureTime?.startOn).format('YYYY-MM-DD') || '-';
        const lectureStartOn = moment(item.lectureTime?.startOn).format('HH:mm') || '-';
        const lectureEndOn = moment(item.lectureTime?.endOn).format('HH:mm') || '-';
        const lectureDay = moment(item.lectureTime?.startOn).format('ddd') || '-';
        const oneTimeAmount = item.ticketType === 'P' ? '-' : item.oneTimeAmount;
        const oneTimeCount = item.ticketType === 'P' ? '-' : item.maxCoupon;
        const deductedAmount = item.ticketType === 'P' ? '-' : item.deductedAmount;
        const deductedCount = item.ticketType === 'P' ? '-' : item.deductedCount;
        const cumulativeAmount = item.ticketType === 'P' ? '-' : item.cumulativeAmount;
        const cumulativeCount = item.ticketType === 'P' ? '-' : item.cumulativeCount;
        const remainingAmount = item.ticketType === 'P' ? '-' : item.remainingAmount;
        const remainingCount = item.ticketType === 'P' ? '-' : item.remainingCount;
        const totalAmount = item.totalAmount;
        const status = item.status ? BOOKING_STATUS_LABEL[item.status] : '차감';

        return {
          수업: lectureType,
          수강권: ticketType,
          날짜: lectureDate,
          수업시작: lectureStartOn,
          수업종료: lectureEndOn,
          요일: lectureDay,
          회원명: item.memberName,
          수강권명: item.ticketName,
          '회당 금액': oneTimeAmount,
          '전체 횟수': oneTimeCount,
          '차감 금액': deductedAmount,
          '차감 횟수': deductedCount,
          '누적사용 금액': cumulativeAmount,
          '누적사용 횟수': cumulativeCount,
          미수업금: remainingAmount,
          '잔여 횟수': remainingCount,
          '결제 금액': totalAmount,
          '수업 강사': item.staffName,
          출결: status,
        };
      });
    },

    async downloadExcel() {
      const json = this.formatJSON(this.filteredData);
      this.$utils.downloadExcel(json, `수업매출_현황${this.date.date}~${this.date.endDate}.xlsx`);
    },

    handleSortChange(e) {
      this.sort = e.order;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  @include flex(column);
  margin: auto;
  padding: 0 30px 30px 30px;
  max-width: 1440px;
  gap: 30px;

  /deep/ .el-pagination {
    @include flex(row, center, center);
  }

  .summary {
    display: grid;
    column-gap: 16px;
    grid-template-columns: auto 1fr;

    &.daterange {
      grid-template-columns: 1fr;
    }
  }
}
</style>
