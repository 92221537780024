<template>
  <section class="boards-detail">
    <div class="boards-detail__buttons">
      <PlainButton
        v-if="active_tab === 'studio-notice'"
        size="large"
        type="info"
        :disabled="!board.prev"
        @click="routerAddress('prev')"
      >
        <i class="el-icon-arrow-left" /> 이전
      </PlainButton>
      <PlainButton
        v-if="active_tab === 'studio-notice'"
        size="large"
        type="info"
        :disabled="!board.next"
        @click="routerAddress('next')"
      >
        다음 <i class="el-icon-arrow-right" />
      </PlainButton>
      <PlainButton size="large" type="info" @click="routerAddress()"> <i class="el-icon-tickets" /> 목록 </PlainButton>
    </div>

    <!-- 내용 -->
    <div class="boards-detail__wrap">
      <div class="boards-detail__wrap-header">
        <div class="boards-common">
          <h4>{{ board.title }}</h4>

          <div class="info-writer">
            <span class="writer bar" v-show="board.staff">
              <span class="name">{{ board.staff ? board.staff.name : '' }}</span>
              <span class="role">{{ board.staff ? board.staff.roles[0].display_name : '' }}</span>
            </span>
            <span class="writer" v-show="board.member">
              {{ board.member ? board.member.name : '' }}
              <span class="role-member">회원</span>
            </span>
            <span class="write-date">{{ active_tab === 'notice' ? board.updated_at : board.created_at | datetime }}</span>
          </div>
        </div>
        <div class="board-detail" v-if="active_tab === 'notice'">
          <div class="bar">
            <span>공지설정:</span>
            <span>{{ this.popupSetting }}</span>
          </div>
          <div class="bar">
            <span>게시기간:</span>
            <span>{{ this.popupPeriod }}</span>
          </div>
          <div>
            <span>공지대상:</span>
            <span>{{ this.targetMember }}</span>
          </div>
        </div>
      </div>
      <div class="boards-detail__wrap-body">
        <div>
          <div>
            <p v-if="active_tab === 'studio-notice'" class="p-board-notice" v-html="modifiedBoardContents" />
            <p v-else class="p-board-contents" v-text="board.contents" />
          </div>
          <div v-for="attachment in this.board.attachments" :key="attachment.id" class="p-board-img-wrapper">
            <img :src="getImageUrl(attachment.path, 0)" alt="memo image" />
          </div>
        </div>
      </div>
    </div>

    <Reply v-show="active_tab === 'qna'" :board="board" />

    <BottomActionBar contentMaxWidth="1366px" v-if="active_tab === 'notice'">
      <el-button @click="handleClickEdit">수정</el-button>
    </BottomActionBar>
  </section>
</template>

<script>
import Reply from '@components/Boards/Reply';
import { BOARD_TYPES } from '@constants';
export default {
  components: { Reply },
  props: {
    active_tab: String,
  },

  data() {
    return {
      loading: false,
      board: {},
      popupSetting: '',
      targetMember: '',
      popupPeriod: '',
      focus: false,
    };
  },

  computed: {
    boardsPath() {
      switch (this.active_tab) {
        case BOARD_TYPES.NOTICE:
          return '/boards';
        case BOARD_TYPES.QNA: {
          return '/boards/list-qna';
        }
        case BOARD_TYPES.STUDIO_NOTICE:
          return '/boards/studio-notice';
        default:
          return '/';
      }
    },
    modifiedBoardContents() {
      return this.board.contents ? this.board.contents.replace(/_500x0\.png/g, '_2000x0.png') : '';
    },
  },

  watch: {
    $route: {
      handler: 'initData',
      immediate: true,
    },

    board(data) {
      if (this.active_tab !== BOARD_TYPES.NOTICE) return;

      const { target_member, start_on, end_on, is_used_period, is_unlimited_period, is_top_fixed, popup_on } = data;

      this.popupSetting = this.$utils.arrangeStringWithComma([
        { label: '팝업사용', value: !!popup_on },
        { label: '상단고정', value: !!is_top_fixed },
      ]);

      this.targetMember = this.$utils.arrangeStringWithComma([
        { label: '유효회원', value: target_member !== 'expired' },
        { label: '만료회원', value: target_member !== 'active' },
      ]);

      let popupPeriod = null;
      if (is_unlimited_period) {
        popupPeriod = '제한없음';
      } else if (is_used_period) {
        popupPeriod = this.$utils.getPeriod(start_on, end_on);
      } else {
        popupPeriod = '미설정';
      }
      this.popupPeriod = popupPeriod;
    },
  },

  methods: {
    async initData() {
      await this.getBoard();
      if (this.active_tab === BOARD_TYPES.QNA) {
        this.getReply();
      }
    },

    getImageUrl(path, size) {
      return this.$utils.getImagePath(path, size);
    },

    getReply() {
      this.$store.dispatch('reply/getMemo', this.board.id);
    },

    async getBoard() {
      this.loading = true;
      try {
        if (this.active_tab !== BOARD_TYPES.STUDIO_NOTICE) {
          const res = await this.$api.boards.get(this.$route.params.id);
          const newData = { ...this.board, ...res.data };
          this.board = newData;
        } else {
          const res = await this.$api.notification.getNotiDetail(this.$route.params.id, {
            all: 1,
            search: this.$route.query.search,
            sort_by: 'id',
          });
          this.board = {
            ...res.data.data,
            contents: res.data.data.context,
            prev: res.data.prev,
            next: res.data.next,
          };
        }
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.loading = false;
      }
    },

    routerAddress(type) {
      const { search, page } = this.$route.query;
      let path = this.boardsPath;

      if (type) {
        const { prev, next } = this.board;
        const id = type === 'prev' ? prev.id : next.id;
        path = `${this.boardsPath}/${id}`;
      }

      const query = `?page=${page || 1}` + `${search ? `&search=${search}` : ''}`;

      return this.$router.push(`${path}${query}`);
    },

    handleClickEdit() {
      const id = this.$route.params.id;
      this.$router.push(`/boards/notice/form?id=${id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.boards-detail {
  @include flex(column);
  &__buttons {
    @include flex(row, center, flex-end);
    gap: 8px;
    margin-bottom: 10px;
  }
  &__wrap {
    border: solid 1px #cccccc;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    &-header {
      @include flex(column, center, flex-end);
      gap: 14px;
      border-bottom: solid 1px $gray-300;
      padding: 0 0 16px;
      flex-wrap: wrap;

      .boards-common {
        @include flex(row, center, space-between);
        width: 100%;
        gap: 20px;

        h4 {
          flex: 1;
          padding-left: 5px;
          white-space: normal;
          word-break: break-all;
        }
      }
      .info-writer {
        text-align: right;
        padding-top: 10px;

        .writer {
          font-size: 13px;
          color: $gray-800;

          .name {
            font-weight: 500;
          }
          .role {
            padding-left: 5px;
            &-member {
              padding-left: 5px;
              color: $gray-500;
            }
          }
        }
        .write-date {
          padding-left: 24px;
          font-size: 13px;
        }
      }

      .board-detail {
        @include flex(row, center, flex-start);
        border-radius: 4px;
        margin-right: auto;
        gap: 20px;
        padding: 9px 12px;
        background: #f7f7f7;

        > div {
          @include flex(row, center, space-between);
          gap: 4px;
          font-size: 13px;
          color: #606266;
        }
      }

      .bar {
        position: relative;
        &:after {
          content: '';
          width: 1px;
          height: 12px;
          position: absolute;
          top: 50%;
          right: -12px;
          margin-top: -6px;
          background: rgba(144, 147, 153, 0.4);
        }
      }
    }
    .start-end-date {
      font-size: 12px;
    }
    &-body {
      padding: 24px 0 48px;
      .p-board-notice {
        /deep/ p {
          margin: 10px 0;
        }
      }
      .p-board-contents {
        white-space: pre-line;
        font-size: 14px;
        word-break: break-all;
      }
      .p-board-img-wrapper {
        margin-top: 48px;
        img {
          max-width: 100%;
        }
      }
      textarea {
        border: none;
        min-height: auto !important;
      }
      .area-edit {
        border: 1px solid #ccc;
        border-radius: 4px;
        &.focus {
          border-color: $deep-sky-blue;
        }
        .attached-images {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 24px;
          padding: 16px;
          &__item {
            position: relative;
            img {
              width: 100%;
            }
            button {
              background: $gray-500;
              color: #fff;
              font-size: 16px;
              position: absolute;
              top: -12px;
              right: -12px;
              &:hover {
                background: $gray-200;
                color: $charcoal-grey;
              }
            }
          }
        }
      }
      .buttons {
        @include flex(row, center, space-between);
        margin-top: 16px;
        .wrap-attached-img {
          input {
            visibility: hidden;
          }
          .info-text {
            padding-left: 5px;
            font-size: 12px;
            color: $gray-500;
          }
        }
        .wrap-btns {
          button.danger {
            margin-right: 8px;
          }
        }
      }
    }
  }
  .bottom-action-bar-container {
    /deep/ button {
      width: 112px;
    }
  }
}
</style>
