import api from '@api';
import _ from 'lodash';
import moment from 'moment';

const DEFAULT_LECTURE = {
  course: {},
  instructor: {
    profile: {},
  },
};

export const state = {
  loading: false,
  lecture: {
    ...DEFAULT_LECTURE,
  },
  lectureId: '',
  memosLoading: false,
  memos: [],
  bookableTicketsLoading: false,
  bookableTickets: [],
  searchKeyword: '',
  pagination: {
    page: 1,
    limit: 10,
  },
  total: 0,
  courseLoading: false,
  course: {},
};

export const getters = {
  loading: state => state.loading,
  lecture: state => state.lecture,
  lectureId: state => state.lectureId,
  memosLoading: state => state.memosLoading,
  memos: state => state.memos,
  bookableTicketsLoading: state => state.bookableTicketsLoading,
  bookableTickets: state => state.bookableTickets,
  searchKeyword: state => state.searchKeyword,
  pagination: state => state.pagination,
  total: state => state.total,
  courseLoading: state => state.courseLoading,
  course: state => state.course,
};

export const mutations = {
  SET_BOOKABLE_LIST_INIT() {
    state.searchKeyword = '';
    state.total = 0;
    state.pagination = { page: 1, limit: 10 };
    state.bookableTickets = [];
  },

  SET_BOOKABLE_LIST_TOTAL(state, total) {
    state.total = total;
  },

  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_LECTURE(state, lecture) {
    state.lecture = {
      ...lecture,
    };
  },

  SET_LECTURE_ID(state, lectureId) {
    state.lectureId = lectureId;
  },

  SET_MEMOS_LOADING(state, loading) {
    state.memosLoading = loading;
  },

  SET_MEMOS(state, memos) {
    state.memos = [...memos];
  },

  SET_BOOKABLE_TICKETS_LOADING(state, loading) {
    state.bookableTicketsLoading = loading;
  },

  SET_PAGINATION(state, value) {
    state.pagination = {
      ...state.pagination,
      ...value,
    };
  },

  SET_SEARCH_MEMBER(state, keyword) {
    state.searchKeyword = keyword;
  },

  SET_BOOKABLE_TICKETS(state, tickets) {
    state.bookableTickets = tickets;
  },

  SET_COURSE_LOADING(state, loading) {
    state.courseLoading = loading;
  },

  SET_COURSE(state, course) {
    state.course = {
      ...course,
    };
  },
};

export const actions = {
  async getLecture({ commit }, lectureId) {
    try {
      commit('SET_LOADING', true);
      const res = await api.lecture.get(lectureId);
      commit('SET_LECTURE', res.data.lecture);
    } catch (error) {
      commit('SET_LECTURE', DEFAULT_LECTURE);
    } finally {
      commit('SET_LOADING', false);
    }

    return;
  },

  async getMemos({ commit }, lectureId) {
    try {
      commit('SET_MEMOS_LOADING', true);
      const res = await api.memo.getAll('lectures', lectureId);
      commit('SET_MEMOS', res.data);
    } catch (error) {
      commit('SET_MEMOS', []);
    } finally {
      commit('SET_MEMOS_LOADING', false);
    }

    return;
  },

  getBookableTickets: _.debounce(async ({ commit }, start_on) => {
    try {
      commit('SET_BOOKABLE_TICKETS_LOADING', true);
      let params = {
        date: moment(start_on).format('YYYY-MM-DD'),
        page: state.pagination.page,
        limit: state.pagination.limit,
      };
      if (state.searchKeyword) {
        params = {
          ...params,
          search: state.searchKeyword,
        };
      }

      const res = await api.userTicket.getBookableList(state.lectureId, params);

      commit('SET_BOOKABLE_LIST_TOTAL', res.data.meta.total);
      commit('SET_BOOKABLE_TICKETS', res.data.data);
    } catch (error) {
      commit('SET_BOOKABLE_TICKETS', []);
    } finally {
      commit('SET_BOOKABLE_TICKETS_LOADING', false);
    }

    return;
  }, 500),

  async getCourse({ commit }, courseId) {
    try {
      commit('SET_COURSE_LOADING', true);
      const res = await api.course.get(courseId);
      commit('SET_COURSE', res.data.course || {});
    } catch (error) {
      commit('SET_COURSE', {});
    } finally {
      commit('SET_COURSE_LOADING', false);
    }
  },
};
